import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    db: null,
    currentUser: null, // firebase.authのcurrentUser
    user: null, // DB上のcurrentUser
    userOrg: null, // ユーザーが所属する組織
    superUser: null, // management-adminか、ログイン時に割り当て
    superUserOrg: null,
    afterLogin: false,
    configs:{
      query_limit: 500,
      query_limit_for_customer_analyses: 500,
      storage_period: {
        operation_history: null,
        notifications: null,
      }
    },
    // 販売組織情報
    centers: [],
    centersDict: { }, // 辞書型
    customerOrgs:[],
    customerOrgsDict: {}, // 辞書型
    userNotifications: {}, // ユーザーへの通知
    orgNotifications: {}, // 組織への通知
    // デバイス受付センター情報
    receptions: [],
    receptionsDict: { }, // 辞書型

    roles:[
      {role: 'management-admin', name: '運営主体管理ユーザー'},
      {role: 'management', name: '運営主体一般ユーザー'},
      {role: 'reception-admin', name: 'デバイス受付センター管理ユーザー'},
      {role: 'reception', name: 'デバイス受付センター一般ユーザ'},
      {role: 'customer-admin', name: '医療機関管理ユーザー'},
      {role: 'customer', name: '医療機関一般ユーザ'},
    ],
    rolesDict:{
      'management-admin': '運営主体管理ユーザー',
      'management': '運営主体一般ユーザー',
      'center-admin': '販売組織管理ユーザー',
      'center': '販売組織一般ユーザ',
      'reception-admin': 'デバイス受付センター管理ユーザー',
      'reception': 'デバイス受付センター一般ユーザー',
      'customer-admin': '医療機関管理ユーザー',
      'customer': '医療機関一般ユーザ',
    },
    deviceStatusNames: [
      '入荷済', '発送済み', '販売スタッフ受領済み', '未使用',
      '使用中', '受付センター着荷', '解析中', '解析完了', '未使用期限切れ',
      '廃棄済み',
      '紛失',
      '解析エラー',
      '全て',
    ],
    deviceStatusNameValueDict: [
      { text: '全て',             value: -1 },
      { text: '使用中',             value: 4 },
      { text: '受付センター着荷',     value: 5 },
      { text: '解析完了',           value: 7 },
      { text: '廃棄済み',           value: 9 },
    ],
    deviceStatusNameValueDictForReception: [
      { text: '全て',             value: -1 },
      { text: '使用中',           value: 4 },
      { text: '受付センター着荷',   value: 5 },
      { text: '保管 (解析完了)',  value: 7 },
      { text: '廃棄済み',         value: 9 },
    ],
    deviceStatusNameValueDictForCustomer: [
      { text: '全て',             value: -1 },
      { text: '使用中',           value: 4 },
      { text: '受付センター着荷',   value: 5 },
      { text: '解析完了',         value: 7 },
      { text: '廃棄済み',         value: 9 },
    ],
    deviceStatusColors:['grey', 'grey', 'grey', 'grey',
      'grey', 'grey', 'grey', 'green', 'red',
      'gray',
      'yellow accent-2',
      'red',
    ],
    logOperationTypes: {
      'menu': 'メニュー選択',
      'devices': 'デバイス管理',
      'device_new': '入荷記録',
      'device_collect': '受付記録',
      'device_dispose': '廃棄記録',
      'staffs': 'スタッフ管理',
      'customer_staffs': '医療機関スタッフ管理',
      'staff_orgs': '組織管理',
      'customer_orgs': '医療機関管理',
      'patient': '患者情報',
      'analysis': '心電図解析管理',
      'auth': '認証',
      'download': 'ダウンロード',
      'send_sign_in_link_email': 'ログイン認証メール',
      'config': '設定',
      'qr_code': '二次元コード',
      'notifications_management': 'お知らせ設定',
    },
    logOperationTypesForReception: {
      'menu': 'メニュー選択',
      'devices': 'デバイス管理',
      'device_collect': '受付記録',
      'device_dispose': '廃棄記録',
      'staffs': 'スタッフ管理',
      'upload': 'アップロード',
      'auth': '認証',
      'send_sign_in_link_email': 'ログイン認証メール',
    },
    logOperationTypesForCustomer: {
      'menu': 'メニュー選択',
      'devices': 'デバイス管理',
      'customer_staffs': '医療機関スタッフ管理',
      'patient': '患者情報',
      'analysis': '心電図解析管理',
      'auth': '認証',
      'download': 'ダウンロード',
      'send_sign_in_link_email': 'ログイン認証メール',
      'qr_code': '二次元コード',
    },
    orgTypes:{
      'management': '運営主体',
      'reception': 'デバイス受付センター',
      'customer': '医療機関',
    },
    termsOfServicePdfs: [
      {
        name: 'terms_of_service-egholter-2022_3_24.pdf',
        label: '「EG Holterシステム」利用規約',
      },
    ],
    termsOfServiceForAnalysisPdfs: [
      {
        name: 'terms_of_service-analysis-2022_5_18.pdf',
        label: 'マイホルターⅡオプション解析利用規約',
      },
    ],
    privacyPolicy: {
      name: 'privacy_policy-test.pdf',
      label: '「EG Holterシステム」プライバシーポリシー',
    },
    showTerms: {},
    showTermsForAnalysis: {},
    youtubeEmbedVideos: [
      {
        src: 'https://www.youtube.com/embed/VTXZQc8Uybg',
        title: 'EG Holterシステム解説 概要編',
      },
      {
        src: 'https://www.youtube.com/embed/bYsypRQHLNg',
        title: 'EG Holterシステム解説 結果入手編',
      },
      {
        src: 'https://www.youtube.com/embed/ZyI_LTEGRgk',
        title: '【旧】起動から貼付方法編（基本）',
      },
      {
        src: 'https://www.youtube.com/embed/5UTYCOC3tEE',
        title: '【旧】貼付方法Tips（縦に貼りづらい方）',
      },
    ],
    localVideos: [
      {
        src: 'videos/eg_holter_px30_guide.mp4',
        title: '【新】サービス全体概要',
      },
    ],
    ecgTypes: {
      1: 'PX 1.0',
      2: 'PE 3.0',
    },
    itemCd2EcgType: {
      '001': 1,
      '006': 1,
      '008': 2,
      '009': 2,
    }
  },
  mutations: {
    // Firestoreを登録
    setDb(state, db){
      state.db = db;
    },
    afterLogin(state) {
      state.afterLogin = true;
    },
    setUser(state, user){
      // console.log('setUser', user);
      state.user = user;
      // スーパーユーザーの設定
      if (user && user.role == 'management-admin') {
        state.superUser = user; // 他のアカウントなりすまし時に復元できるようにする
      }
    },
    setUserOrg(state, org){
      // console.log('setUserOrg', org);
      state.userOrg = org;
      if (state.user.role == 'management-admin') {
        state.superUserOrg = org;
      }
    },
    // スーパーユーザー時のロールの変更
    updateRole(state, role){
      if (state.superUser != null){
        state.user.role = role;
      }
    },
    // 他のユーザーになりすます
    updateUser(state, {user, org}) {
      if (state.superUser != null) {
        state.user = user;
        if (org != null) {
          state.userOrg = org;
        }
      }
    },
    // スーパーユーザーの本来のアカウントに戻る
    recoverUser(state) {
      if (state.superUser != null) {
        state.user = state.superUser;
        state.userOrg = state.superUserOrg;
      }
    },
    setConfigs(state, configs) {
      state.configs = configs;
    },
    setShowTerms(state, terms) {
      state.showTerms = terms;
    },
    setShowTermsForAnalysis(state, terms) {
      state.showTermsForAnalysis = terms;
    },
    setCenters(state, centers) {
      let dict = {}
      state.centers = centers;
      centers.forEach((center) => {
        dict[center.id] = center;
      });
      state.centersDict = dict;
    },
    setReceptions(state, receptions) {
      let dict = {};
      state.receptions = receptions;
      receptions.forEach((reception) => {
        dict[reception.id] = reception;
      });
      state.receptionsDict = dict;
    },
    setCustomerOrgs(state, customerOrgs) {
      let dict = {}
      state.customerOrgs = customerOrgs;
      customerOrgs.forEach((org) => {
        dict[org.id] = org;
      });
      state.customerOrgsDict = dict;
    },
    setUserNotifications(state, notifications) {
      console.log('setUserNotifications', notifications);
      state.userNotifications = notifications;
    },
    setOrgNotifications(state, notifications) {
      console.log('setOrgNotifications', notifications);
      state.orgNotifications = notifications;
    },
    setUserTableLinesParPage(state, tableLinesParPage) {
      console.log('setUserTableLinesParPage', tableLinesParPage);
      state.user.table_lines_par_page = tableLinesParPage;
    }
  },
  actions: actions,
  modules: {
  }
})
