<template>
  <v-dialog v-model="dialogModel" persistent max-width="600px"  @keydown="escCheck">
      <v-card>
        <v-card-title class="headline primary" >
          ロール・ユーザー切り替え（テスト用）
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-label>現在のアカウント状態: ロール: {{currentRole}} / 現在のユーザー: {{currentUser}}</v-label>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="roleModel"
                  :items="$store.state.roles"
                  item-text="name"
                  item-value="role"
                  label="ロール"
                  required
                  @change="onSelectedRoleChanged"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-if="orgs"
                  v-model="orgId"
                  :items="orgs"
                  item-text="name"
                  item-value="id"
                  label="所属組織"
                  :rules="notNull"
                  required
                  @change="changeStaffs"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-if="staffs"
                  v-model="staffId"
                  :items="staffs"
                  item-text="name"
                  item-value="id"
                  :rules="notNull"
                  label="切り替える対象のスタッフ"
                  required
                ></v-select>
              </v-col>              
            </v-row>
            
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('close')">閉じる</v-btn>
          <v-btn color="blue darken-1" text @click="changeRole">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

import dateformat from 'dateformat';

let unsubscribeStaffs = null;

export default {
  props: {
    value: Boolean,    
  },
  data() {
    return {
      dialogModel: false,
      roles:[
        'management-admin', 
        'management',
        'center-admin',
        'center',
        'customer-admin',
        'customer',
      ],
      roleModel: null,
      currentRole: null,
      currentUser: null,
      orgs: null,
      orgsDict: null,
      orgId: null,
      staffs: null,
      staffsDict: null,
      staffId: null,
      user: null,
      notNull: [
        v => !!v || '選択は必須です!。',
      ],
    }
  },
  methods: {
    changeRole() {
      
      if (this.staffId != null) {
        // ユーザーを変更
        let staff = this.staffsDict[this.staffId];
        this.$store.commit('updateUser', {user:staff, org:this.orgsDict[staff.org_id]});
        this.$store.commit('updateRole', this.roleModel);
        this.user = staff;
      } else if (this.roleModel.startsWith('management')){
        // ユーザーを戻す
        this.$store.commit('recoverUser');
        this.$store.commit('updateRole', this.roleModel);
      } else {
        // 閉じない
        this.$root.$alert('警告', '役割とユーザー切り替えを行うにはスタッフの選択まで行なってください。');
        return;
      }
      this.dialogModel = false;

      // 役割とユーザー情報の保存
      let asUser = JSON.stringify(this.$store.state.user);
      let asRole = JSON.stringify(this.$store.state.user.role);
      window.localStorage.setItem('asUser', asUser); // 仮ユーザーの保存
      window.localStorage.setItem('asRole', asRole); // 仮ロールの保存

      this.$emit('close', { role: this.roleModel, user: this.user });
    },
    escCheck(e) {
      console.log(e);
      if (e.code == 'Escape') {
        this.dialogModel = false;
        this.$emit('close');
      }
    },
    onSelectedRoleChanged(role) {
      // 選択しているロールが変更された場合
      if (role.startsWith('management')) {
        // 本部スタッフは変更できない。
        this.orgs = null;
        this.orgsDict = null;
        this.staffs = null;
      } else if (role.startsWith('customer')) {
        this.orgs = this.$store.state.customerOrgs;
        this.orgsDict = this.$store.state.customerOrgsDict;
      } else if (role.startsWith('center')) {
        console.log('center');
        this.orgs = this.$store.state.centers;
        this.orgsDict = this.$store.state.centersDict;
      } else if (role.startsWith('reception')) {
        console.log('reception');
        this.orgs = this.$store.state.receptions;
        this.orgsDict = this.$store.state.receptionsDict;
      }
    },
    async changeStaffs(org_id) {
      if (unsubscribeStaffs != null) unsubscribeStaffs();

      if (this.roleModel != null) {
        let collectionPath = (this.roleModel.startsWith('customer')) ? 'customer_staffs': 'staffs';
        let query = this.$db.collection(collectionPath)
          .where('org_id', '==', org_id)
          .where('deleted', '==', null)
          .orderBy('modified').limit(this.$store.state.configs.query_limit);

        unsubscribeStaffs = query.onSnapshot((snapshot) => {
          // スタッフ情報の取得
          let items = [];
          let dict = {};
          snapshot.forEach((doc) => {
            let data = doc.data();
            if (data.deleted == null) {
              data.created = data.created ? dateformat(new Date(data.created.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
              data.modified = data.modified ? dateformat(new Date(data.modified.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
              data.id = doc.id;
              items.push(data);
              dict[doc.id] = data;
            }
          });
          items = items.reverse();
          this.staffs = items;
          this.staffsDict = dict;
        });

      }
    }

  },
  mounted() {
    this.dialogModel = this.value;    
    this.currentUser = this.$store.state.user.email;

  }
}
</script>

<style scoped>
  .primary {
    color: white;
  }
</style>
