<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title  class="d-flex align-center">
        <span class="font-weight-light">EG Holterシステム</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn v-if="currentUser != null" class="mr-2" @click="logout" text color="white">
        <v-icon>
          exit_to_app
        </v-icon>
        &nbsp;
        ログアウト
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-if="$store.state.user != null"
      v-model="drawer"
      app
      color="grey lighten-4"
      :disable-resize-watcher="disableResizeWatcher"
    >
      <v-list dense class="grey lighten-4">
        <v-list-item-group
          mandatory
          v-model="selectedItem"
          color="primary"
        >
          <template v-for="(item, i) in items">

            <v-divider v-if="item.divider" :key="i" dark class="my-3"></v-divider>

            <v-list-item  v-else-if="item.button" :key="i" @click="item.onClick">
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="grey--text">{{ item.button }}</v-list-item-title>
                <v-list-item-title v-if="item.subText" class="grey--text">{{ item.button }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-else-if="
                (
                  item.isTermsOfServiceForAnalysis
                  && !isContractedAnalysisOption
                  && $store.state.user.role.endsWith('-admin')
                  && !isTechCp2207  // 22/07 技師解析CPに対する一時的な対応
                )
                || !item.isTermsOfServiceForAnalysis
              "
              :key="i"
              :value="item.path"
              @click="item ? push(item): false;"
              :disabled="item.header ? true : false"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="item.text2" class="">{{ item.text2 }}</v-list-item-title>
                <v-list-item-title v-if="item.subText2" class="">{{ item.subText2 }}</v-list-item-title>
                <v-list-item-title class="grey--text">{{ item.text }}</v-list-item-title>
                <v-list-item-title v-if="item.subText" class="grey--text">{{ item.subText }}</v-list-item-title>
                <v-list-item-title v-if="item.text3" class="grey--text">{{ item.text3 }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-content>
      <router-view></router-view>
    </v-content>
    <role-change-dialog
      v-if="roleChangeDialogModel"
      v-model="roleChangeDialogModel"
      @close="changeRole">
    </role-change-dialog>
    <common-dialog ref="dialog">
    </common-dialog>
    <v-overlay :z-index="10000" :value="isProgressing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import CommonDialog from './components/CommonDialog';
import RoleChangeDialog from '@/components/RoleChangeDialog';

let onAuthStateChangedUnmount = null;

export default {
  name: 'App',

  components: {
    CommonDialog,
    RoleChangeDialog
  },

  data () {
    let data = {
      roleChangeDialogModel: false,
      currentUser: null,
      drawer: false,
      disableResizeWatcher: false,
      isProgressing: false,
      // メニュー項目
      items: null,
      items_customer: [
        {
          header: true,
          icon: "account_circle",
          text: "",
          path: null
        },
        { divider: true},
        {
          icon: "home",
          text: "ホーム",
          path: "/"
        },
        { divider: true },
        { icon: "people_outline", text: "スタッフ管理", path: "/staffs" },
        { divider: true },
        { icon: "show_chart", text: "心電図解析管理", path: "/analyses" },
        { icon: "mdi-waveform", text: "検査開始登録", path: "/patient-register" },
        // { icon: 'mdi-file-sign', text: 'オプション解析お申込', path: '/terms-of-service-for-analysis', isTermsOfServiceForAnalysis: true},
        { divider: true },
        { icon: "help", text: "ヘルプ", path: "/help" },
      ],
      items_center: [
        {
          header: true,
          icon: "account_circle",
          text: "",
          path: null
        },
        { divider: true },
        {
          icon: "home",
          text: "ホーム",
          path: "/"
        },
        { divider: true},
        { icon: "people_outline", text: "スタッフ管理", path: "/staffs" },
        { divider: true },
        { icon: "people", text: "顧客管理", path: "/customer-orgs" },
        { icon: "upload", text: "CSVアップロード", path: "/csv-upload" },
        { icon: "history", text: "CSVアップロード履歴", path: "/csv-upload-history" },
        { icon: "favorite", text: "心電計トレーサビリティ", path: "/traceability" },
        { icon: "mdi-arrow-u-left-top", text: "デバイス登録取り消し", path: "/cancellation-of-shipment" },
        { icon: "mdi-clipboard-text-clock-outline", text: "操作履歴", path: "/operation-history" },
        { divider: true },
        { icon: "help", text: "ヘルプ", path: "/help" },
      ],
      items_reception: [
        {
          header: true,
          icon: "account_circle",
          text: "",
          path: null
        },
        { divider: true },
        {
          icon: "home",
          text: "ホーム",
          path: "/"
        },
        { divider: true},
        { icon: "people_outline", text: "スタッフ管理", path: "/staffs" },
        { divider: true },
        { icon: "input", text: "心電計等受付記録", path: "/collections" },
        { icon: "mdi-pin", text: "心電計保管一覧", path: "/keeps" },
        { icon: "delete", text: "心電計廃棄記録", path: "/disposals" },
        { icon: "assignment", text: "保管管理", path: "/inventories" },
        { icon: "favorite", text: "心電計トレーサビリティ", path: "/traceability" },
        { icon: "mdi-clipboard-text-clock-outline", text: "操作履歴", path: "/operation-history" },
        { divider: true },
        { icon: "help", text: "ヘルプ", path: "/help" },
      ],
      items_admin: [
        {
          header: true,
          icon: "account_circle",
          text: "",
          path: null
        },
        { divider: true },
        {
          icon: "home",
          text: "ホーム",
          path: "/"
        },
        { divider: true },
        { icon: "people", text: "顧客管理", path: "/customer-orgs" },
        { icon: "assignment", text: "在庫管理", path: "/inventories" },
        { icon: "favorite", text: "心電計トレーサビリティ", path: "/traceability" },
        { icon: "mdi-clipboard-text-clock-outline", text: "操作履歴", path: "/operation-history" },
        { divider: true },
        { icon: "business", text: "デバイス受付センター", path: "/receptions" },
        { divider: true },
        { icon: "people", text: "運営主体スタッフ", path: "/mgt-staffs" },
        { icon: "settings", text: "設定", path: "/configurations", forSuperUsers: true },
        { icon: "mdi-bullhorn", text: "お知らせ設定", path: "/notifications_management" },
        { icon: "settings", text: "メールテンプレート", path: "/email-templates", forSuperUsers: true },
        //{ icon: "info", text: "このアプリについて", path: "/about"},
        { icon: "help", text: "ヘルプ", path: "/help" },
      ],
      selectedItem: null,
    };
    return data;
  },
  computed: {
    isContractedAnalysisOption() {
      return 'is_contracted_analysis_option' in this.$store.state.userOrg && this.$store.state.userOrg.is_contracted_analysis_option === true ? true : false;
    },
    isTechCp2207() {
      return 'is_tech_cp_2207' in this.$store.state.configs && this.$store.state.configs.is_tech_cp_2207 === true ? true : false;
    },
  },
  methods: {

    // Progressive Circularを表示させながら非同期処理を実行する
    async withProgressiveCircular(promise) {
      this.isProgressing = true;
      try {
        let result = await promise;
        return result;
      } catch (e) {
        throw e;
      } finally {
        this.isProgressing = false;
      }
    },
    async push(item) {
      // console.log(path, this.$router.currentRoute);
      if (!item.path) return;
      let path = item.path;
      if (path === '/' + this.$router.currentRoute.name) return;
      if (item.forSuperUsers) {
        // console.log(this.$store.state.configs.superUsers);
        if (this.$store.state.user.role != 'management-admin') {
          await this.$root.$alert('警告', 'この機能は運営主体管理ユーザーのみ利用できます。');
          return;
        }
      } else if (item.forAdminUsers) {
        console.log(this.$store.state.user.role);
        if (!this.$store.state.user.role.endsWith('-admin')) {
          await this.$root.$alert('警告', 'この機能は管理ユーザーのみ利用できます。');
          return;
        }
      }
      if (item.text) {
        // 操作記録
        this.$functions.log({
          tags: ['menu', path.replace('/', '')],
          message: `メニュー「${item.text}」 を選択しました。`,
        });
      }
      this.$router.replace(
        path,
        () => {
        },
        () => {
          // console.log('Vue Router replace onAbort');
        }
      );
    },
    logout() {
      // なりすまし状態をクリア
      window.localStorage.removeItem('asUser');
      window.localStorage.removeItem('asRole');
      this.$functions.log({
        tags: ['auth', 'logout'],
        message: `ログアウトしました。`,
      });
      this.$auth.signOut().then(() => {
        this.currentUser = null;
        this.$store.commit('setUser', null);
        // APIキー付きのURLの場合は更新ボタンでまたエラー画面が表示されるので
        // 再接続することでAPIキーを削除する
        window.location.href = '/#/login';
      });
    },
    showRoleChangeDialog() {
      this.roleChangeDialogModel = true;
    },
    updateMenu(role) {
      if (this.items && this.$store.state.superUser) {
        this.items.pop();
        //console.log(this.items.length);
      }
      switch (role) {
        case 'management':
        case 'management-admin':
          this.items = this.items_admin;
          break;
        case 'center':
        case 'center-admin':
          this.items = this.items_center;
          break;
        case 'reception':
        case 'reception-admin':
          this.items = this.items_reception;
          break;
        case 'customer':
        case 'customer-admin':
          this.items = this.items_customer;
          break;
      }
      this.items[0].text = `${this.$store.state.user.email} `;
      this.items[0].subText = `${this.$store.state.userOrg.name}`;
      this.items[0].text3 = `${this.$store.state.rolesDict[role]}`;
      if (this.$store.state.superUser) {
        this.items.push(
          { icon: "settings", button: "ロール・ユーザー切り替え", onClick: this.showRoleChangeDialog}
        );

        if (!role.startsWith('management')) {
          this.items[0].text2 = ` ${this.$store.state.superUser.email} `;
          this.items[0].subText2 = ` ${this.$store.state.superUserOrg.name}`;
        }
      }
    },
    changeRole(params) {
      console.log('changeRole', params);
      this.roleChangeDialogModel = false;
      if (params == null) return;
      // ロールの変更
      this.updateMenu(params.role);
      if (this.$route.path != '/terms-of-service') {
        this.$router.replace({path: '/login'});
        setTimeout(() => {
          this.$router.replace({path: '/'});
        }, 100);
      }
    }
  },
  mounted() {
    onAuthStateChangedUnmount = this.$auth.onAuthStateChanged(async (user) => {
      this.currentUser = user;
      if (this.$store.state.afterLogin) return; // ログイン直後の場合は再接続されるため、ここで処理を中断する
      if (user != null) {
        let pendingPath = window.localStorage.getItem('pendingPath');
        pendingPath = pendingPath ? pendingPath : '/';
        window.localStorage.removeItem('pendingPath');
        // this.subscribeConfigs();

        // 存在チェック
        let email = this.currentUser.email;
        let userType = 'staffs';
        let querySnapshot = null;

        try {
          querySnapshot = await this.$db.collection(userType)
            .where('deleted', '==', null)
            .where('email', '==', email)
            .get();
          if (querySnapshot.empty) throw new Error('Unauthorized');
        } catch (e) {
          // 医療機関スタッフの場合は、Firestore rules によって弾かれる
          console.warn(e);
          querySnapshot = null;
        }

        if (querySnapshot == null) {
          try {
            userType = 'customer_staffs';
            querySnapshot = await this.$db.collection(userType)
              .where('deleted', '==', null)
              .where('email', '==', email)
              .get();
            if (querySnapshot.empty) throw new Error('Unauthorized');
          } catch (e) {
            // 医療機関スタッフでも無い場合は、Firestore rules によって弾かれる
            console.warn(e);
            querySnapshot = null;
          }
        }

        if (querySnapshot == null) {
          this.$root.$alert('エラー', '未登録のユーザーです。');
          this.logout();
          return;
        }

        let doc = querySnapshot.docs[0]; // 最初の検索結果を取得し、userRefで参照
        //console.log(`${userType}s/${doc.id}`, doc);
        let userRef = this.$db.doc(`${userType}/${doc.id}`);

        // 認証のため先にデータを取得し状態に保存しておく（そうしないとこの先の画面遷移ができない）
        const userDoc = await userRef.get();
        let userData = userDoc.data();
        userData.id = userDoc.id;
        this.$store.commit('setUser', userData);
        // 認証のため先にデータを取得し状態に保存しておく（組織情報）
        const userOrgTypeTmp = userData.role.startsWith('customer') ? 'customer_orgs': 'staff_orgs';
        const userOrgRefTmp = this.$db.doc(`${userOrgTypeTmp}/${userData.org_id}`);
        const userOrgDocTmp = await userOrgRefTmp.get();
        let userOrgDataTmp = userOrgDocTmp.data();
        userOrgDataTmp.id = userOrgDocTmp.id;
        this.$store.commit('setUserOrg', userOrgDataTmp);

        // 医療機関スタッフの場合はメニューを閉じたままにする
        this.drawer = userData.type == 'customer' ? false : true;

        // 設定の読み込み、監視
        this.$store.dispatch('subscribeConfigs');
        // センター情報、顧客組織情報、販売事業組織情報の読み込み
        this.$store.dispatch('subscribeCenters');
        this.$store.dispatch('subscribeReceptions');
        this.$store.dispatch('subscribeCustomerOrgs');


        // メニューバー初期化
        this.updateMenu(userData.role);
        // 移動
        this.push({path: pendingPath});

        // メニュー選択済み表示の初期設定
        this.selectedItem = pendingPath;

        userRef.onSnapshot((doc) => {
          let user = doc.data();
          user.id = doc.id;
          this.$store.commit('setUser', user);
          let org_id = user.org_id;

          let userOrgType = user.role.startsWith('customer') ? 'customer_orgs': 'staff_orgs';
          let userOrgRef = this.$db.doc(`${userOrgType}/${org_id}`);
          this.items[0].text = `${this.$store.state.user.email}`;
          userOrgRef.onSnapshot((userOrgDoc) => {
            if (!userOrgDoc.empty){
              let data = userOrgDoc.data();
              data.id = userOrgDoc.id;
              this.$store.commit('setUserOrg', data);
              this.items[0].subText = `${this.$store.state.userOrg.name}`;
            }
            this.updateMenu(user.role);

            // 画面再描画時の保存したデータを復元
            if (user.role == 'management-admin') {
              let asUserJSON = window.localStorage.getItem('asUser');
              let asRoleJSON = window.localStorage.getItem('asRole');
              //console.log('asUserJSON, asRoleJSON', asUserJSON, asRoleJSON);
              if (asUserJSON != null && asRoleJSON != null) {
                let asUser = JSON.parse(asUserJSON);
                let asRole = JSON.parse(asRoleJSON);

                if (!asUser.role.startsWith('management')){
                  let orgsDict = {};
                  if (asUser.role.startsWith('customer')) {
                    orgsDict = this.$store.state.customerOrgsDict;
                  } else if (asUser.role.startsWith('center')) {
                    orgsDict = this.$store.state.centersDict;
                  } else if (asUser.role.startsWith('reception')) {
                    orgsDict = this.$store.state.receptionsDict;
                  }
                  this.$store.commit('updateUser', {user: asUser, org:orgsDict[asUser.org_id]});
                  this.$store.commit('updateRole', asRole);
                  this.changeRole({role: asRole});
                }
              }
            }
          });

        });
      }
    });

    // unmountCenters = this.subscribeCenters();
    // unmountCustomerOrgs = this.subscribeCustomerOrgs();

    this.$root.$alert = this.$refs.dialog.alert;
    this.$root.$alert_no_button = this.$refs.dialog.alert_no_button;
    this.$root.$alert_multi_line = this.$refs.dialog.alert_multi_line;
    this.$root.$confirm = this.$refs.dialog.confirm;
    this.$root.$confirm_multi_line = this.$refs.dialog.confirm_multi_line;
    this.$root.$progressive = this.withProgressiveCircular;
  },
  beforeDestroy() {
    if (onAuthStateChangedUnmount) onAuthStateChangedUnmount();
    // unsubscribeDbConfigs();
    // if (unmountCenters) unmountCenters();
    // if (unmountCustomerOrgs) unmountCustomerOrgs();
    // if (unsubscribeUserOrg) unsubscribeUserOrg();

  },
  watch: {
    $route(to) {
      // メニューハイライトの制御
      let path = to.path
      if (path == '/about') path = '/help';  // 「このアプリについて」はヘルプの下にあるためヘルプにする

      if (this.$store.state.user && this.$store.state.user.type == 'customer') {
        // 医療機関スタッフの場合
        if (path == '/traceability' || path == '/operation-history') path = '/help';
        if (path === '/') {
          // ホーム画面の場合はメニューを非表示
          this.disableResizeWatcher = true; // 画面サイズ変動による自動表示を無効
          this.drawer = false;
        } else {
          this.disableResizeWatcher = false;   // 画面サイズ変動による自動表示を有効
          // 画面遷移直後の画面サイズによって、メニューの表示/非表示を変える（自動では制御してくれない）
          switch (this.$vuetify.breakpoint.name) {
            case 'lg':
            case 'xl':
              this.drawer = true;
              break;
            default:
              this.drawer = false;
              break;
          }
        }
      }

      this.selectedItem = path;
    }
  }
};
</script>
