import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0C2F8F',
        secondary: '#2196F3',
        accent:  '#E50112',
      },
      dark: {
        primary: 'white'
      }
    },
  },
  icons: {
    iconfont: 'md',
  },
})