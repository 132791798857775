import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/Help.vue'),
  },
  // {
  //   path: '/admins',
  //   name: 'admins',
  //   component: () => import('./views/Admins.vue'),
  // },
  // {
  //   path: '/users',
  //   name: 'users',
  //   component: Users,
  // },
  // {
  //   path: '/apps',
  //   name: 'apps',
  //   component: () => import('./views/Apps.vue'),
  // },

  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/customer-orgs',
    name: 'customer_orgs',
    component: () => import('../views/CustomerOrgs.vue'),
  },
  {
    path: '/mgt-staffs',
    name: 'mgt-staffs',
    component: () => import('../views/MgtStaffs.vue'),
  },
  // {
  //   path: '/centers',
  //   name: 'centers',
  //   component: () => import('../views/Centers.vue'),
  // },
  {
    path: '/receptions',
    name: 'receptions',
    component: () => import('../views/Receptions.vue'),
  },
  {
    path: '/inventories',
    name: 'inventories',
    component: () => import('../views/Inventories.vue'),
  },
  {
    path: '/traceability',
    name: 'traceability',
    component: () => import('../views/Traceability.vue'),
  },
  {
    path: '/collections',
    name: 'collections',
    component: () => import('../views/Collections.vue'),
  },
  {
    path: '/patient-register',
    name: 'patient-register',
    component: () => import('../views/PatientRegister.vue'),
  },
  {
    path: '/analyses',
    name: 'analyses',
    component: () => import('../views/Analyses.vue'),
  },
  {
    path: '/disposals',
    name: 'disposals',
    component: () => import('../views/Disposals.vue'),
  },
  /*
  {
    path: '/losts',
    name: 'losts',
    component: () => import('../views/Losts.vue'),
  },
  */
  {
    path: '/configurations',
    name: 'configurations',
    component: () => import('../views/Configurations.vue'),
  },
  {
    path: '/staffs',
    name: 'staffs',
    component: () => import('../views/Staffs.vue'),
  },
  // {
  //   path: '/activity-logs',
  //   name: 'activity-logs',
  //   component: () => import('../views/ActivityLogs.vue'),
  // },
  // {
  //   path: '/activity-log-uploader',
  //   name: 'activity-log-uploader',
  //   component: () => import('../views/ActivityLogUploader.vue'),
  // },
  {
    path: '/keeps',
    name: 'keeps',
    component: () => import('../views/Keeps.vue'),
  },
  // {
  //   path: '/cancellation-of-shipment',
  //   name: 'cancellation-of-shipment',
  //   component: () => import('../views/CancellationOfShipment.vue'),
  // },

  {
    path: '/email-templates',
    name: 'email-templates',
    component: () => import('../views/EmailTemplates.vue'),
  },
  {
    path: '/operation-history',
    name: 'operation-history',
    component: () => import('../views/OperationHistory.vue'),
  },
  {
    path: '/dongle',
    name: 'dongle',
    component: () => import('../views/DongleRegister.vue')
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: () => import('../views/TermsOfService.vue'),
  },
  // {
  //   path: '/terms-of-service-for-analysis',
  //   name: 'terms-of-service-for-analysis',
  //   component: () => import('../views/TermsOfServiceForAnalysis.vue'),
  // },
  {
    path: '/csv-upload',
    name: 'csv-upload',
    component: () => import('../views/CSVUpload.vue'),
  },
  // {
  //   path: '/csv-upload-history',
  //   name: 'csv-upload-history',
  //   component: () => import('../views/CSVUploadHistory.vue'),
  // },
  {
    path: '/notifications_management',
    name: 'notifications_management',
    component: () => import('../views/NotificationsManagement.vue'),
  },
]

const router = new VueRouter({
  routes
})

export default router
