<template>
  <v-dialog
    persistent
    v-model="isShowing"
    :max-width="width"
    @keydown.esc="cancel" 
  >
    <v-card>
      <v-card-title class="headline primary" >
        {{title}}
      </v-card-title>
      <p></p>
      <v-card-text v-if="mode=='alert_no_button' || mode=='alert_multi_line' || mode=='confirm_multi_line'">
        <div v-for="(msg, i) in message" :key="i">{{ msg }}</div>
      </v-card-text>
      <v-card-text v-else>{{message}}</v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn v-if="mode!='alert_no_button'" color="primary darken-1" text @click.native="agree">はい</v-btn>
        <v-btn v-if="mode=='confirm' || mode=='confirm_multi_line'" color="grey darken-1" text @click.native="cancel">いいえ</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>

      <v-card-text v-if="isMyholterRedirect == true">
        <v-img
          :src="require('@/assets/myholter_popupblock.svg')"
          contain
          max-width="600"
        ></v-img>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShowing: false,
      title: '確認',
      message: '',
      width: '50%',
      mode: 'alert',
      resolve: null,
      reject: null,
      isMyholterRedirect: false,
    }
  },
  methods: {
    alert_no_button(title = '確認', message = [], options = { width: '480px' }) {
      this.mode = 'alert_no_button';
      return this.show(title, message, options);
    },
    alert(title = '確認', message = '', options = { width: '480px' }) {
      this.mode = 'alert';
      return this.show(title, message, options);
    },
    alert_multi_line(title = '確認', message = '', options = { width: '480px' }) {
      this.mode = 'alert_multi_line';
      return this.show(title, message, options);
    },
    show(title = '確認', message = '', options = { width: '480px' }) {
      this.isShowing = true;      
      this.title = title;
      this.message = message;

      // オプションを反映
      let tmp = Object.assign({ width: '480px' }, options);
      this.width = tmp.width;
      this.isMyholterRedirect = 'isMyholterRedirect' in tmp ? tmp.isMyholterRedirect : false;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm(title = '確認', message = '', options = { width: '480px' }) {
      this.mode = 'confirm';
      return this.show(title, message, options);
    },
    confirm_multi_line(title = '確認', message = '', options = { width: '480px' }) {
      this.mode = 'confirm_multi_line';
      return this.show(title, message, options);
    },
    agree() {
      this.isShowing = false;
      this.resolve(true);
    },
    cancel() {
      this.isShowing = false;
      this.resolve(false);
    }
  }
}
</script>

<style scoped>
  .primary {
    color: white;
  }
</style>
