export default {
  init(firebase) {
    const functions = firebase.app().functions('asia-northeast1');
    // エミュレータの利用の有無
    const useEmulator = false;
    if (useEmulator) functions.useEmulator("localhost", 5001);
    return {
      verifyEmail: functions.httpsCallable('verifyEmail'),
      createPassword: functions.httpsCallable('createPassword'),
      log: functions.httpsCallable('log'),
      registerMyholterLoginRequest: functions.httpsCallable('registerMyholterLoginRequest'),
      isDuplicatedEmail: functions.httpsCallable('isDuplicatedEmail'),
      sendMailStaffRegistration: functions.httpsCallable('sendMailStaffRegistration'),
      sendMailChangedAnalysisOpinionType: functions.httpsCallable('sendMailChangedAnalysisOpinionType'),
      sendMailAnalysisOpinionContract: functions.httpsCallable('sendMailAnalysisOpinionContract'),
    };
  },
};