var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","color":"primary","dark":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-light"},[_vm._v("EG Holterシステム")])]),_c('v-spacer'),(_vm.currentUser != null)?_c('v-btn',{staticClass:"mr-2",attrs:{"text":"","color":"white"},on:{"click":_vm.logout}},[_c('v-icon',[_vm._v(" exit_to_app ")]),_vm._v("   ログアウト ")],1):_vm._e()],1),(_vm.$store.state.user != null)?_c('v-navigation-drawer',{attrs:{"app":"","color":"grey lighten-4","disable-resize-watcher":_vm.disableResizeWatcher},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"grey lighten-4",attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"mandatory":"","color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},[_vm._l((_vm.items),function(item,i){return [(item.divider)?_c('v-divider',{key:i,staticClass:"my-3",attrs:{"dark":""}}):(item.button)?_c('v-list-item',{key:i,on:{"click":item.onClick}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"grey--text"},[_vm._v(_vm._s(item.button))]),(item.subText)?_c('v-list-item-title',{staticClass:"grey--text"},[_vm._v(_vm._s(item.button))]):_vm._e()],1)],1):(
              (
                item.isTermsOfServiceForAnalysis
                && !_vm.isContractedAnalysisOption
                && _vm.$store.state.user.role.endsWith('-admin')
                && !_vm.isTechCp2207  // 22/07 技師解析CPに対する一時的な対応
              )
              || !item.isTermsOfServiceForAnalysis
            )?_c('v-list-item',{key:i,attrs:{"value":item.path,"disabled":item.header ? true : false},on:{"click":function($event){item ? _vm.push(item): false;}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[(item.text2)?_c('v-list-item-title',{},[_vm._v(_vm._s(item.text2))]):_vm._e(),(item.subText2)?_c('v-list-item-title',{},[_vm._v(_vm._s(item.subText2))]):_vm._e(),_c('v-list-item-title',{staticClass:"grey--text"},[_vm._v(_vm._s(item.text))]),(item.subText)?_c('v-list-item-title',{staticClass:"grey--text"},[_vm._v(_vm._s(item.subText))]):_vm._e(),(item.text3)?_c('v-list-item-title',{staticClass:"grey--text"},[_vm._v(_vm._s(item.text3))]):_vm._e()],1)],1):_vm._e()]})],2)],1)],1):_vm._e(),_c('v-content',[_c('router-view')],1),(_vm.roleChangeDialogModel)?_c('role-change-dialog',{on:{"close":_vm.changeRole},model:{value:(_vm.roleChangeDialogModel),callback:function ($$v) {_vm.roleChangeDialogModel=$$v},expression:"roleChangeDialogModel"}}):_vm._e(),_c('common-dialog',{ref:"dialog"}),_c('v-overlay',{attrs:{"z-index":10000,"value":_vm.isProgressing}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }