// 通知用クラス
class Notifications {

  // 定数の定義
  get ORDER_PLACED(){
    return 1;
  }

  get ORDER_SHIPPED() {
    return 2;
  }
  
  get ANALYSIS_COMPLETED() {
    return 3;
  }

  get HOLTER_PREEXPIRED() {
    return 4;
  }

  get HOLTER_EXPIRED() {
    return 5;
  }

  get HOLTER_UNCOLLECTED() {
    return 6;
  }

  get ANALYSIS_ERROR() {
    return 7;
  }

  get STAFF_REGISTRATION() {
    return 8;
  }
  
  get COLLECTION_PATH() {
    return 'notifications';
  }

  get USER_TYPES() {
    return {
      ORDER_PLACED: 'staff',
    }
  }

  // Firestoreオブジェクト及びVuexストアの登録(シングルトン時の初期化）
  initialize(firebase, store = null){
    this.firebase = firebase;
    this.db = firebase.firestore();
    this.store = store;
  }


  // タイプから、通知のメタデータ（アイコン等）を取得する
  getMetadataByType(type) {
    switch (type) {
      case this.ORDER_PLACED:
        return {
          icon: 'assignment',
          color: 'green',
          icon_color: 'white',
          is_shown_after_confirmed: true // 確認後の通知は表示対象か？
        };
      case this.ORDER_SHIPPED:
        return {
          icon: 'local_shipping',
          color: 'green',
          icon_color: 'white',
          is_shown_after_confirmed: true
        };
      case this.ANALYSIS_COMPLETED:
        return {
          icon: 'show_chart',
          color: 'green',
          icon_color: 'white',
          is_shown_after_confirmed: false
        };
      case this.HOLTER_PREEXPIRED:
        return {
          icon: 'notification_important',
          color: 'yellow',
          icon_color: 'white',
          is_shown_after_confirmed: false
        };
      case this.HOLTER_EXPIRED:
        return {
          icon: 'warning',
          color: 'red',
          icon_color: 'yellow',
          is_shown_after_confirmed: false
        };
      case this.HOLTER_UNCOLLECTED:
        return {
          icon: 'notification_important',
          color: 'yellow',
          icon_color: 'white',
          is_shown_after_confirmed: false
        };
      case this.ANALYSIS_ERROR:
        return {
          icon: 'warning',
          color: 'red',
          icon_color: 'yellow',
          is_shown_after_confirmed: false
        };
      case this.STAFF_REGISTRATION:
        return {
          icon: 'warning',
          color: 'green',
          icon_color: 'white',
          is_shown_after_confirmed: false
        };
    }
    return {
      icon: 'assignment',
      color: 'green',
      icon_color: 'white',
      is_shown_after_confirmed: false
    };
  }

  // 通知の生成
  async create(data) {
    console.log('Notifications.create: ', data);
    data.created = this.firebase.firestore.FieldValue.serverTimestamp();
    data.modified = data.created;
    data.deleted = null;
    let collectionRef = this.db.collection(this.COLLECTION_PATH);
    let docRef = null;
    try {
      docRef = await collectionRef.add(data);
    } catch (e) {
      console.error(e);      
    }
    return docRef;
  }

  // 確認記録
  async confirm(notificationId) {
    let user_id = this.store.state.user.id;
    let ts = this.firebase.firestore.FieldValue.serverTimestamp();
    // 対象となるNotificationの取得
    let docRef = await this.db.collection(this.COLLECTION_PATH).doc(notificationId);
    let confirmed;
    try {
      let doc = await docRef.get();
      confirmed  = doc.get('confirmed');
      if (confirmed == null) {
        confirmed = {};
      }
      // confirmedの加工,同じuser_idが書き込まれた場合は上書き
      confirmed[user_id] = ts;
    } catch (e) {
      throw e;
    }
    try {
      await docRef
        .update({modified: ts, confirmed});
    } catch (e){
      throw e;
    }
  }

  // 通知の削除
  async delete(notificationId) {
    try {
      let ts = this.firebase.firestore.FieldValue.serverTimestamp();
      await this.db.collection(this.COLLECTION_PATH).doc(notificationId)
        .update({deleted: ts, modified: ts});        
    } catch (e){
      throw e;
    }
  }

  // 指定したユーザー及び、そのユーザーが所属する組織に届いた通知の監視を開始する
  subscribe() {
    // ユーザー通知の取得
    //console.log('subscribe', this.store.state);

    this.store.dispatch('subscribeNotifications', this.store.state.user.id);
    // 組織通知の取得    
    this.store.dispatch('subscribeNotifications', null);
    return 'subscribed';
  }

  unsubscribe() {
    this.store.dispatch('unsubscribe', 'userNotifications');
    this.store.dispatch('unsubscribe', 'orgNotifications');
  }

  // confirmed({id, user_id}) {

  // }
}

export default new Notifications(); // シングルトン