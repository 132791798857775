import dateformat from 'dateformat';
import Notifications from '@/notifications';

// 更新監視解除用メソッド
let unsubscribes = {
  centers: null,
  customerOrgs: null,
  reception: null,
  receptionOrgs: null,
  userNotifications: null,
  orgNotifications: null,
  userOrg: null, // 在庫数の反映のため
  configs: null, // 瀬テチ
};
// 更新解除メソッド
let unsubscribe = (key) => {
  if (unsubscribes[key]) {
    unsubscribes[key]();
    unsubscribes[key] = null;
  }
};

export default {
  async subscribeConfigs({state, commit}) {
    unsubscribe('configs');
    let configsRef = state.db.doc('config/management');
    unsubscribes.configs = configsRef.onSnapshot((doc) => {
      if (doc.exists) {
        let data = doc.data();
        commit('setConfigs', data);
      }
    });
  },
  async saveConfigs({state}, params) {
    let configsRef = state.db.doc('config/management');
    await configsRef.update(params);
    // 更新したら自動で変更されるはず。
  },
  setShowTerms({commit}, terms) {
    commit('setShowTerms', terms);
  },
  setShowTermsForAnalysis({commit}, terms) {
    commit('setShowTermsForAnalysis', terms);
  },
  // センター情報の読み込み
  async subscribeCenters({state, commit}) {
    // 一旦解除
    unsubscribe('centers');

    if (state.user.role.startsWith('center')) {
      // 販売組織スタッフの場合、自身の組織のみ（在庫管理で利用中）
      unsubscribes.centers = state.db.collection(`staff_orgs`).doc(state.user.org_id).onSnapshot((doc) => {
        const data = doc.data();
        data.created = data.created ? dateformat(new Date(data.created.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
        data.modified = data.modified ? dateformat(new Date(data.modified.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
        data.id = doc.id;
        commit('setCenters', [data]);
      }, (e) => {
        console.log('error', e);      
      });
      return;
    } 

    // 運営主体スタッフでなければ終了
    if (!state.user.role.startsWith('management')) return;

    let centersRef =  state.db.collection(`staff_orgs`)
      .where('type', '==', 'center') // 販売組織
      .where('deleted', '==', null);

    centersRef = centersRef.orderBy('modified').limit(state.configs.query_limit); // 全て対象の時

    unsubscribes.centers = centersRef
      .onSnapshot((querySnapshot) => {
      // 最初および変更時の読み取り
        let items = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          if (data.deleted == null) {
            data.created = data.created ? dateformat(new Date(data.created.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
            data.modified = data.modified ? dateformat(new Date(data.modified.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
            data.id = doc.id;
            items.push(data);             
          }
        });
        items = items.reverse();          
        //console.log('subscribeCenters onSnapshot', state.configs.query_limit, items, items.length);
        commit('setCenters', items);
      }, (e) => {
        console.log('error', e);       
      });

  },
  // デバイス受付センター情報の読み込み
  async subscribeReceptions({state, commit}) {
    // 一旦解除
    unsubscribe('receptions');

    if (state.user.role.startsWith('reception')) {
      // デバイス受付センタースタッフの場合、自身の組織のみ（在庫管理で利用中）
      unsubscribes.receptions = state.db.collection(`staff_orgs`).doc(state.user.org_id).onSnapshot((doc) => {
        const data = doc.data();
        data.created = data.created ? dateformat(new Date(data.created.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
        data.modified = data.modified ? dateformat(new Date(data.modified.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
        data.id = doc.id;
        commit('setReceptions', [data]);
      }, (e) => {
        console.log('error', e);      
      });
      return;
    } 

    // 運営主体スタッフでなければ終了
    if (!state.user.role.startsWith('management')) return;
      
    let receptionsRef =  state.db.collection(`staff_orgs`)
      .where('type', '==', 'reception') // デバイス受取センター
      .where('deleted', '==', null);

    receptionsRef = receptionsRef.orderBy('modified').limit(state.configs.query_limit); 

    unsubscribes.receptions = receptionsRef
      .onSnapshot((querySnapshot) => {
      // 最初および変更時の読み取り
        let items = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          if (data.deleted == null) {
            data.created = data.created ? dateformat(new Date(data.created.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
            data.modified = data.modified ? dateformat(new Date(data.modified.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
            data.id = doc.id;
            items.push(data);
          }
        });
        items = items.reverse();
        //console.log('subscribeCenters onSnapshot', state.configs.query_limit, items, items.length);
        commit('setReceptions', items);
      }, (e) => {
        console.log('error', e);
      });

  },
  // 医療機関情報の読み込み
  async subscribeCustomerOrgs({state, commit}) {
    // 一旦解除
    unsubscribe('customerOrgs');

    let customerOrgsRef = state.db.collection(`customer_orgs`).where('deleted', '==', null);

    if (state.user.role.startsWith('customer')) {
      // 医療機関の場合、自身の医療機関のみ
      unsubscribes.customerOrgs = state.db.collection(`customer_orgs`).doc(state.user.org_id).onSnapshot((doc) => {
          const data = doc.data();
          data.created = data.created ? dateformat(new Date(data.created.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
          data.modified = data.modified ? dateformat(new Date(data.modified.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
          data.id = doc.id;
          commit('setCustomerOrgs', [data]);
        }, (e) => {
          console.log('error', e);      
        });
      return;
    } else if (state.user.role.startsWith('reception')) {
      // デバイス受付センタースタッフの場合、自身の組織に紐づけられている医療機関のみ
      customerOrgsRef = customerOrgsRef.where('reception_id', '==', state.user.org_id);
    }

    customerOrgsRef = customerOrgsRef.orderBy('modified').limit(state.configs.query_limit); // 全て対象の時
    
    unsubscribes.customerOrgs = customerOrgsRef
      .onSnapshot((querySnapshot) => {
      // 最初および変更時の読み取り
        let items = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          if (data.deleted == null) {
            data.created = data.created ? dateformat(new Date(data.created.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
            data.modified = data.modified ? dateformat(new Date(data.modified.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
            data.id = doc.id;
            items.push(data);             
          }
        });
        items = items.reverse();          
        //console.log('onSnapshot', state.configs.query_limit, items, items.length);
        commit('setCustomerOrgs', items);
      }, (e) => {
        console.log('error', e);      
      });
  },
  subscribeNotifications({state, commit}, user_id) {
    //console.log('subscribeNotifications', user_id);
    // 一旦解除
    unsubscribe(user_id ? 'userNotifications': 'orgNotifications');
    // org_id / user_idと一致するものを探索する
    // センター情報の格納
    let query =  state.db.collection(`notifications`)
      .where('deleted', '==', null)
      .where('org_id', '==', state.user.org_id)
      .where('user_id', '==', user_id)
      .orderBy('modified', 'desc').limit(state.configs.query_limit); // 全て対象の時

    let unsubscribeFunc = query
      .onSnapshot((querySnapshot) => {
      // 最初および変更時の読み取り
        let items = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();

          let metadata = Notifications.getMetadataByType(data.type);

          if (metadata.is_shown_after_confirmed || (( data.confirmed && data.confirmed[state.user.id] == null) || data.confirmed == null)) {
            data.created = data.created ? dateformat(new Date(data.created.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
            data.modified = data.modified ? dateformat(new Date(data.modified.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
            data.id = doc.id;
            
            data.icon = metadata.icon;
            data.color = metadata.color;
            data.icon_color = metadata.icon_color;
            items.push(data);             
          }
        });
        //console.log('onSnapshot', state.configs.query_limit, items, items.length);
        commit(user_id ? 'setUserNotifications': 'setOrgNotifications', items);
    }, (e) => {
      console.log('error', e);      
    });

    // ユーザー通知か組織通知か
    if (user_id != null)
      unsubscribes.userNotifications = unsubscribeFunc;
    else
      unsubscribes.orgNotifications = unsubscribeFunc;
  },
  unsubscribe(key) {
    unsubscribe(key);
  }

}